<template>
  <div class="row">
    <modal
        name="select-user-modal"
        :adaptive="true"
        height="auto"
        width="750"
    >
      <select-sample-user-modal
          :users="sampleUsers"
          :model.sync="selectedUserProxy"
          @onCloseModal="onCloseModal"
          @onConfirm="onCreateSample"
      ></select-sample-user-modal>
    </modal>
    <modal
        name="update-sample-image-modal"
        :adaptive="true"
        height="auto"
        width="750"
    >
      <div class="card card-custom">
        <div class="card-header py-7">
          <h3>{{ $t("editSample.please_select_image_to_upload") }}</h3>
        </div>
        <div class="card-body card-body-scroll">
          <div class="w-100">
            <img class="h-200px" id="preview-content-image" src="" alt="">
          </div>
          <file-input
              name="content-image"
              :model.sync="currentContent"
              :title="$t('editSample.image')"
              accept="image/*"
              @fileChange="onNewImageSelected"
          ></file-input>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button @click="closeModalByName('update-sample-image-modal')"
                  type="button"
                  class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">{{ $t('editSample.close') }}
          </button>
          <button @click="addContent(3, 'update-sample-image-modal', 1)"
                  type="button"
                  class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
          </button>
        </div>
      </div>
    </modal>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:title>
          <span>{{ $t('general.create_meeting') }}</span>
        </template>
        <template v-slot:preview>
          <div class="d-flex justify-content-end" v-if="sampleEntryTypeValidation">
            <img
                @click="sampleImageProxy != null ? openSelectImageModal() : closeModalByName('update-sample-image-modal')"
                :src="mediaBase + '/icon/no-photos.png'" :class="{'cursor-pointer': sampleImageProxy != null }" alt=""
                id="sample-image-holder" class="w-100px h-100px object-fill">
          </div>
          <div class="form-group">
            <label class="typo__label">{{ $t('meeting.meeting_date_time') }}</label>
            <div class="col-lg-3">
              <div class="d-inline-block">
                <date-picker-input
                    name="model_name"
                    :model.sync="meetingModel.meetingStartDate"
                ></date-picker-input>
                <b-form-timepicker v-model="meetingModel.meetingStartHour" :data-icon="null" size="sm" locale="tr" :nowButton="true" :label-close-button="$t('general.close')" :label-now-button="$t('general.new')"></b-form-timepicker>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label">{{ $t('general.customer') }}</label>
            <multiselect
                v-model="meetingModel.meetingCompany"
                :placeholder="$t('meeting.please_select_customer_to_create_meeting')"
                label="title"
                :options="companies"
                :option-height="104"
                track-by="name"
                :show-labels="false">
              <template slot="singleLabel" slot-scope="props">
                <template v-if="props.option">
                  <img class="option__image company-option-preview-image"
                      :src="props.option.avatar_file">
                  <span class="option__desc ml-4 d-inline-block">
                  <span class="option__title">
                  {{ props.option.name }}
                  </span>
                </span>
                </template>
                <template v-else>
                  <h6>
                    <span v-html="getIconByKey('icons.meeting.company-participant', {
                        class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                  })"></span>
                    {{ $t('general.please_select_company_for_sample') }}
                  </h6>
                </template>
              </template>
              <template slot="option" slot-scope="props">
                <img
                    class="option__image company-option-preview-image"
                    :src="props.option.avatar_file">
                <span class="option__desc ml-4 d-inline-block">
                  <span class="option__title">
                  {{ props.option.name }}
                  </span>
                </span>
              </template>
            </multiselect>
          </div>
          <div class="d-flex" style="align-items: center;" v-if="!selectedCompanyValidation">
            <button @click="$router.push({name: 'general.company.create', params: {
              parent: 'meeting',
              parentName: 'general.meeting',
              parentRouteName: 'meeting.create_meeting_normal',
            }})" class="btn btn-sm btn-outline-dark mr-2 btn-pill py-1 px-2">
              <span v-html="getIconByKey('icons.sample.add-new-client', {
                        class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                  })"></span>
              {{ $t('createOriginalSample.add_new_client') }}
            </button>
            <info-tooltip
                :helper-text="$t('createOriginalSample.to_create_new_customer')"
            ></info-tooltip>
          </div>
          <div class="w-100 my-3" v-if="selectedCompanyValidation">
            <h5 class="text-center">
              <span v-html="getIconByKey('icons.meeting.collection-product', {
                        class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                  })"></span>
              {{ $t('createOriginalSample.collection_product_information') }}</h5>
          </div>
          <div class="form-group" v-if="selectedCompanyValidation">
            <label class="typo__label">{{ $t('general.product_type') }}</label>
            <multiselect
                v-model="selectedProductTypeProxy"
                :placeholder="$t('createOriginalSample.please_select_product_type')"
                label="title"
                :options="productTypes"
                :option-height="104"
                track-by="name"
                :show-labels="false">
              <template slot="singleLabel" slot-scope="props">
                <template v-if="props.option">
                  <span class="option__desc d-inline-block">
                    <span class="option__title">
                      <span v-if="Number(props.option.id) === 1"
                            v-html="getIconByKey('icons.sample.k-product', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 2"
                            v-html="getIconByKey('icons.sample.fabric', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 3"
                            v-html="getIconByKey('icons.sample.accessory', {})"
                      ></span>
                      {{ props.option.name }}
                    </span>
                </span>
                </template>
                <template v-else>
                  <h6>{{ $t('general.please_select_company_for_sample') }}</h6>
                </template>

              </template>
              <template slot="option" slot-scope="props">
                <span class="option__desc d-inline-block">
                    <span class="option__title">
                      <span v-if="Number(props.option.id) === 1"
                            v-html="getIconByKey('icons.sample.k-product', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 2"
                            v-html="getIconByKey('icons.sample.fabric', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 3"
                            v-html="getIconByKey('icons.sample.accessory', {})"
                      ></span>
                      {{ props.option.name }}
                    </span>
                </span>
              </template>
            </multiselect>
          </div>
          <div class="d-flex justify-content-center" style="align-items: center;" v-if="selectedProductTypeValidation">
            <button class="btn btn-sm btn-pill py-1 px-5"
                    @click="sampleEntryType = 1"
                    :class="{
                'btn-success': sampleEntryType === 1,
                'btn-outline-success': sampleEntryType !== 1,
                }"
            >
              <span v-html="getIconByKey('icons.sample.edit', {})"
              ></span> {{ $t('createOriginalSample.enter_product_name') }}
            </button>
            <strong class="d-inline-block mx-5"> {{ $t('createOriginalSample.or') }} </strong>
            <button class="btn btn-sm btn-pill py-1 px-5"
                    @click="sampleEntryType = 2"
                    :class="{
                'btn-success': sampleEntryType === 2,
                'btn-outline-success': sampleEntryType !== 2,
                }"
            >
              <span v-html="getIconByKey('icons.sample.search-qr-code', {})"
              ></span>
              {{ $t('createOriginalSample.scan_qr_code') }}
            </button>
          </div>
          <template v-if="Number(sampleEntryType) === 1">
            <text-input
                :title="$t('createOriginalSample.product_name')"
                :placeholder="$t('createOriginalSample.please_enter_product_name')"
                :model.sync="productNameProxy"
                :is-valid="productNameValidation ? true : null"
                :is-inline="false"
            ></text-input>

            <h6 class="text-success mt-5 text-center" v-if="productNameValidation">*
              {{ $t('editSample.please_select_image_for_sample_beforehand') }}
              <info-tooltip
                  :helper-text="$t('editSample.please_select_image_for_sample_beforehand')"
              ></info-tooltip>
              <button type="button"
                      @click="openSelectImageModal"
                      class="btn btn-outline-success btn-sm">
                {{ $t("editSample.select") }}
              </button>
            </h6>
            <h6 v-if="selectedUserProxy" class="pt-5 pb-2 text-center text-success">
              {{ $t('createOriginalSample.responsible_person') }} : {{ userInfo.name }}</h6>
            <div class="d-flex justify-content-center" v-if="productContents.length !== 0 ">
              <button type="button"

                      @click="openSelectUserModal"
                      class="btn btn-sm btn-outline-success">
                {{ $t("createOriginalSample.select_responsible_person_for_this_sample") }}
              </button>
            </div>
            <template v-if="selectedUserValidation">
              <div v-if="productContents !== null && productContents.length !== 0" class="my-5">
                <template v-if="productContentByType(1).length !== 0">
                  <h1>{{ $t('general.text') }}</h1>
                  <hr>
                  <div class="row py-5">
                    <div class="col-lg-6 col-md-6 col-sm-12" v-for="(content, index) in productContentByType(1)">
                      <meeting-text-holder-card @onClickDelete="onDeleteContent" :is-deletable="true"
                                                :content="content"></meeting-text-holder-card>
                    </div>
                  </div>
                </template>

                <template v-if="productContentByType(2).length !== 0">
                  <h1>{{ $t('general.videos') }}</h1>
                  <hr>
                  <div class="row py-5">
                    <div class="col-lg-6 col-md-6 col-sm-12" v-for="(content, index) in productContentByType(2)">
                      <meeting-video-holder-card @onClickDelete="onDeleteContent" :is-deletable="true"
                                                 :content="content"></meeting-video-holder-card>
                    </div>
                  </div>
                </template>

                <template v-if="productContentByType(3).length !== 0">
                  <h1>{{ $t('general.images') }}</h1>
                  <hr>
                  <div class="row py-5">
                    <div class="col-lg-6 col-md-6 col-sm-12" v-for="(content, index) in productContentByType(3)">
                      <meeting-image-holder-card @onClickDelete="onDeleteContent" :is-deletable="true"
                                                 :content="content"></meeting-image-holder-card>
                    </div>
                  </div>
                </template>

                <template v-if="productContentByType(4).length !== 0">
                  <h1>{{ $t('general.audios') }}</h1>
                  <hr>
                  <div class="row py-5">
                    <div class="col-lg-6 col-md-6 col-sm-12" v-for="(content, index) in productContentByType(4)">
                      <meeting-audio-player-holder-card @onClickDelete="onDeleteContent" :is-deletable="true"
                                                        :contents="content"></meeting-audio-player-holder-card>
                    </div>
                  </div>
                </template>
              </div>
              <div class="row d-flex justify-content-center my-5">
                <modal
                    name="add-product-image-modal"
                    :adaptive="true"
                    height="auto"
                    width="750"
                >
                  <div class="card card-custom">
                    <div class="card-header py-7">
                      <h3>{{ $t("editSample.please_select_image_to_upload") }}</h3>
                    </div>
                    <div class="card-body card-body-scroll overflow-y-auto">
                      <div class="w-100">
                        <img src="" id="preview-temp-content-image" alt="" class="h-200px">
                      </div>
                      <file-input
                          name="temp-content-image"
                          :model.sync="currentContent"
                          @fileChange="onNewProductImageSelected"
                          :title="$t('editSample.image')"
                          accept="image/*"
                      ></file-input>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                      <button @click="closeModalByName('add-product-image-modal')"
                              type="button"
                              class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                        {{ $t('editSample.close') }}
                      </button>
                      <button @click="addContent(3, 'add-product-image-modal')"
                              type="button"
                              :disabled="currentContent == null || currentContent.length === 0"
                              class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                      </button>
                    </div>
                  </div>
                </modal>
                <a @click="openAddProductImageModal"
                   class="btn btn-sm btn-icon btn-light-primary btn-circle btn-lg mx-3 cursor-pointer">
                  <i class="fas fa-camera"></i>
                </a>
                <modal
                    name="add-product-video-modal"
                    :adaptive="true"
                    height="auto"
                    width="750"
                >
                  <div class="card card-custom">
                    <div class="card-header py-7">
                      <h3>{{ $t("editSample.please_select_video_to_upload") }}</h3>
                    </div>
                    <div class="card-body card-body-scroll h-50">
                      <div class="w-100">
                        <vue-plyr>
                          <video :src="tempVideoBlob"></video>
                        </vue-plyr>
                      </div>
                      <file-input
                          name="content-video"
                          :model.sync="currentContent"
                          @fileChange="onNewVideoSelected"
                          :title="$t('editSample.video')"
                          accept="video/*"
                      ></file-input>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                      <button @click="closeModalByName('add-product-video-modal')"
                              type="button"
                              class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                        {{ $t('editSample.close') }}
                      </button>
                      <button @click="addContent(2, 'add-product-video-modal')"
                              type="button"
                              class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                      </button>
                    </div>
                  </div>
                </modal>
                <a @click="openAddProductVideoModal"
                   class="btn btn-sm btn-icon btn-light-primary btn-circle btn-lg mx-3 cursor-pointer">
                  <i class="fas fa-video"></i>
                </a>
                <modal
                    name="add-product-audio-modal"
                    :adaptive="true"
                    height="auto"
                    width="750"
                >
                  <div class="card card-custom">
                    <div class="card-header py-7">
                      <h3>{{ $t("editSample.please_select_audio_to_upload") }}</h3>
                    </div>
                    <div class="card-body card-body-scroll">
                      <div class="w-100">
                        <vue-plyr class="bg-gray-200">
                          <audio :src="tempAudioBlob"></audio>
                        </vue-plyr>
                      </div>
                      <file-input
                          name="content-audio"
                          :model.sync="currentContent"
                          @fileChange="onNewAudioSelected"
                          :title="$t('editSample.audio')"
                          accept="audio/*"
                      ></file-input>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                      <button @click="closeModalByName('add-product-audio-modal')"
                              type="button"
                              class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                        {{ $t('editSample.close') }}
                      </button>
                      <button @click="addContent(4, 'add-product-audio-modal')"
                              type="button"
                              class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                      </button>
                    </div>
                  </div>
                </modal>
                <a @click="openAddProductAudioModal"
                   class="btn btn-sm btn-icon btn-light-primary btn-circle btn-lg mx-3 cursor-pointer">
                  <i class="fas fa-microphone"></i>
                </a>

                <modal
                    name="add-product-text-modal"
                    :adaptive="true"
                    height="auto"
                    width="750"
                >
                  <div class="card card-custom">
                    <div class="card-header py-7">
                      <h3>{{ $t("editSample.please_enter_text") }}</h3>
                    </div>
                    <div class="card-body card-body-scroll">
                      <textarea-input
                          :title="$t('editSample.text')"
                          :model.sync="currentContent"
                      ></textarea-input>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                      <button @click="closeModalByName('add-product-text-modal')"
                              type="button"
                              class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                        {{ $t('editSample.close') }}
                      </button>
                      <button @click="addContent(1, 'add-product-text-modal')"
                              type="button"
                              class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                      </button>
                    </div>
                  </div>
                </modal>
                <a @click="openAddProductTextModal"
                   class="btn btn-sm btn-icon btn-light-primary btn-circle btn-lg mx-3 cursor-pointer">
                  <i class="fas fa-pen"></i>
                </a>
              </div>

              <div class="my-4 py-4 d-flex justify-content-center">
                <button type="button" @click="submit(false)" class="btn btn-sm btn-outline-success mr-4">
                  {{ $t('createOriginalSample.save_and_create_instruction') }}
                </button>
                <button type="button" @click="submit(true)" class="btn btn-sm btn-outline-success">
                  {{ $t('createOriginalSample.save_and_continue') }}
                </button>
              </div>
            </template>
          </template>

        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {
  ERROR,
  SUCCESS,
  LOADING,
  GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE,
  COMPANIES,
  PRODUCT_TYPES,
  SELECTED_PRODUCT_TYPE,
  SELECTED_COMPANY,
  SAMPLE_USERS,
  SELECTED_USER,
  SET_SELECTED_USER,
  SET_SELECTED_PRODUCT_TYPE,
  SET_SELECTED_COMPANY,
  PRODUCT_NAME,
  SET_PRODUCT_NAME,
  SAMPLE_IMAGE,
  SET_SAMPLE_IMAGE,
  PRODUCT_CONTENTS,
  APPEND_PRODUCT_CONTENTS,
  SET_PRODUCT_CONTENTS,
  DELETE_PRODUCT_CONTENT_BY_ID, CREATE_ORIGINAL_SAMPLE,
} from '@/core/services/store/sample/original-sample/create.original.sample.module';
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import TextInput from "@/assets/components/inputs/TextInput";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import SelectSampleUserModal from "@/view/pages/samples/layout/meeting-detail/SelectSampleUserModal";
import MeetingImageHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingImageHolderCard";
import MeetingVideoHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingVideoHolderCard";
import MeetingAudioPlayerHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingAudioPlayerHolderCard";
import MeetingTextHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingTextHolderCard";
import $ from "jquery";
import FileInput from "@/assets/components/inputs/FileInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";

import {v4 as uuidv4} from 'uuid'
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import moment from "moment";
import {MOMENT_SYSTEM_DATE_DAY_FORMAT, MOMENT_SYSTEM_HOUR_AND_MINUTE} from "@/core/config/constant";
import FileInputChange from "@/assets/components/inputs/FileInputChange";


export default {
  name: "CreateMeeting",
  components: {
    FileInputChange,
    DatePickerInput,
    TextareaInput,
    FileInput,
    InfoTooltip,
    TextInput,
    SelectInput,
    MultipleSelectInput,
    DashboardBox,
    SelectSampleUserModal,
    MeetingImageHolderCard,
    MeetingAudioPlayerHolderCard,
    MeetingVideoHolderCard,
    MeetingTextHolderCard
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      success: SUCCESS,
      error: ERROR,
      companies: COMPANIES,
      productTypes: PRODUCT_TYPES,
      sampleImage: SAMPLE_IMAGE,
      selectedProductType: SELECTED_PRODUCT_TYPE,
      selectedCompany: SELECTED_COMPANY,
      productName: PRODUCT_NAME,
      sampleUsers: SAMPLE_USERS,
      selectedUser: SELECTED_USER,
      productContents: PRODUCT_CONTENTS,
    }),
    userInfo() {
      if (this.selectedUserProxy == null) {
        return {};
      }
      for (let i = 0; i < this.sampleUsers.length; i++) {
        if (Number(this.sampleUsers[i].id) === Number(this.selectedUserProxy)) {
          return this.sampleUsers[i];
        }
      }
    },
    productNameProxy: {
      set(value) {
        this.setProductName(value);
      },
      get() {
        return this.productName;
      }
    },
    selectedUserProxy: {
      set(value) {
        this.setSelectedUser(value);
      },
      get() {
        return this.selectedUser;
      }
    },
    selectedCompanyProxy: {
      set(value) {
        this.setSelectedCompany(value);
      },
      get() {
        return this.selectedCompany;
      }
    },
    selectedProductTypeProxy: {
      set(value) {
        this.setSelectedProductType(value);
      },
      get() {
        return this.selectedProductType;
      }
    },
    sampleImageProxy: {
      set(value) {
        this.setSampleImage(value);
      },
      get() {
        return this.sampleImage;
      }
    },
    selectedCompanyValidation() {
      return this.selectedCompanyProxy != null;
    },
    selectedProductTypeValidation() {
      return this.selectedProductType != null;
    },
    sampleEntryTypeValidation() {
      return Number(this.sampleEntryType) !== 0;
    },
    productNameValidation() {
      return !(this.productNameProxy == null || this.productNameProxy.length === 0);
    },
    sampleImageValidation() {
      return this.sampleImageProxy != null;
    },
    selectedUserValidation() {
      return this.selectedUserProxy != null;
    }
  },
  data() {
    return {
      sampleEntryType: 0,
      sampleEntryTypeOptions: [
        {
          id: 1,
          name: this.$t('createOriginalSample.create_manually'),
          icon: 'fas fa-hand-point-up'
        },
        {
          id: 2,
          name: this.$t('general.scan_qr_code'),
          icon: 'fas fa-qrcode'
        }
      ],
      meetingModel : {
        meetingStartDate: moment(Date()).format(MOMENT_SYSTEM_DATE_DAY_FORMAT),
        meetingStartHour: moment(Date()).format(MOMENT_SYSTEM_HOUR_AND_MINUTE),
        meetingCompany: null
      },
      currentContent: null,
      videoPlayer: {},
      tempVideoBlob: null,
      tempAudioBlob: null,
    };
  },
  methods: {
    ...mapMutations({
      setSelectedProductType: SET_SELECTED_PRODUCT_TYPE,
      setSelectedCompany: SET_SELECTED_COMPANY,
      setProductName: SET_PRODUCT_NAME,
      setSelectedUser: SET_SELECTED_USER,
      setSampleImage: SET_SAMPLE_IMAGE,
      setProductContents: SET_PRODUCT_CONTENTS,
      appendProductContents: APPEND_PRODUCT_CONTENTS,
      deleteProductContentById: DELETE_PRODUCT_CONTENT_BY_ID,
    }),
    openSelectUserModal() {
      this.$modal.show('select-user-modal');
    },
    openSelectImageModal() {
      this.$modal.show('update-sample-image-modal');
    },
    onCloseModal() {
      this.selectedUserProxy = null;
      this.$modal.hide('select-user-modal')
    },
    onCreateSample() {
      this.$modal.hide('select-user-modal')
    },
    onNewImageSelected(payload) {
      let image = payload.file;
      let id = payload.id;
      let reader = new FileReader();
      reader.onload = function (e) {
        $('#' + id).attr('src', e.target.result);
        $('#' + 'sample-image-holder').attr('src', e.target.result);
      }
      reader.readAsDataURL(image); // convert to base64 string
    },
    onNewProductImageSelected(payload) {
      let image = payload.file;
      let reader = new FileReader();
      reader.onload = function (e) {
        $('#' + payload.id).attr('src', URL.createObjectURL(payload.file));
      }
      reader.readAsDataURL(image);
    },
    onNewVideoSelected(payload) {
      this.tempVideoBlob = URL.createObjectURL(payload.file);
    },
    onNewAudioSelected(payload) {
      this.tempAudioBlob = URL.createObjectURL(payload.file);
    },

    openAddProductImageModal() {
      this.currentContent = null;
      this.$modal.show('add-product-image-modal');
    },
    openAddProductVideoModal() {
      this.currentContent = null;
      this.$modal.show('add-product-video-modal');
    },
    openAddProductAudioModal() {
      this.currentContent = null;
      this.$modal.show('add-product-audio-modal');
    },
    openAddProductTextModal() {
      this.currentContent = null;
      this.$modal.show('add-product-text-modal');
    },
    // setSampleImage(){
    //   // TODO: change sample image on top right of the corner
    // }
    addContent(type, modalName, isSelected = 0) {
      if (isSelected) {
        let temp = this.productContents;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].is_selected) {
            temp.splice(i, 1)
            this.setProductContents(temp);
            break;
          }
        }
      }
      this.$modal.hide(modalName);
      let tempContent = {
        id: uuidv4(),
        content_type_id: type,
        data: this.currentContent,
        is_selected: isSelected,
        user: {
          name: this.authUser.name,
          avatar_file: this.authUser.avatar,
        }
      };
      if (Number(type) === 1) {
        tempContent['file_link'] = this.currentContent;
      } else if (Number(type) === 2) {
        tempContent['file_link'] = this.tempVideoBlob;
      } else if (Number(type) === 3) {
        tempContent['file_link'] = URL.createObjectURL(this.currentContent);
      } else if (Number(type) === 4) {
        tempContent['file_link'] = this.tempAudioBlob;
      }
      this.appendProductContents(tempContent);
    },
    productContentByType(type) {
      let results = [];
      for (let i = 0; i < this.productContents.length; i++) {
        let currentContent = this.productContents[i];
        if (Number(currentContent.content_type_id) === Number(type)) {
          results.push(currentContent);
        }
      }
      return results;
    },
    onDeleteContent(id) {

      let self = this;
      this.$swal.fire({
        title: this.$t('general.are_you_sure'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('general.yes_delete_it'),
        cancelButtonText: this.$t('general.no_cancel_it'),
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false
      }).then(function (result) {
        if (result.isConfirmed) {
          self.$swal.fire({
            title: self.$t('general.deleted'),
            icon: 'success'
          })
          self.deleteProductContentById(id);
        }
      })
    },
    submit(redirect = false) {
      let self = this;
      this.$store.dispatch(CREATE_ORIGINAL_SAMPLE).then((response) => {
        if (response.status) {
          self.sweetAlertSuccess('general.successfully_created').then(() => {
            if (!redirect) {
              self.$router.push({name: 'sample'})
            } else {
              self.$router.push({name: 'sample.product.edit', params: {id: response.data.id}})
            }
          })
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.sample'), route: "sample"},
      {title: this.$t('general.original_sample')}
    ]);

    this.$store.dispatch(GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE, {});
  }
}
</script>

<style scoped>
.company-option-preview-image {
  width: 25px;
  border-radius: 100%;
  height: 25px;
  object-fit: cover;
  display: inline-block
}

.card-body-scroll {
  max-height: 45vh;
  overflow-y: auto;
}
</style>
